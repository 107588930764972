<template>
	<div class="box">
		<div class="header">卡券兑换</div>
		<div class="duihuan">
			<div class="line">卡<span>卡</span>号<input type="text" v-model="kahao" placeholder="请输入卡号"></div>
			<div class="line">兑换码<input type="text" v-model="duihuanma" placeholder="请输入兑换码"></div>
			<el-button class="button" :plain="true" @click="toDuihuan">兑换</el-button>
		</div>
		<div class="header">我的卡券</div>
		<div class="table">
			<el-table :data="List" border>
				<el-table-column prop="card_account" label="卡号" width="500"></el-table-column>
				<el-table-column prop="face_value" label="面额" width="500"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { getToken } from "@/utils/auth"
import { bangka,chongqian,kaquan } from "@/api/pc"
export default {
	name: 'level',
	components: {},
	data: () => {
		return {
			kahao:'',
			duihuanma:'',
			List:[]
		};
	},
	created() {
		this.getList();
	},
	methods: {
		getList(){
			kaquan().then(res => {
				this.List=res.data;
			})
			.catch(err => {
				this.$message.error(err.message)
			})
		},
		toDuihuan(){
			let that=this
			if(!that.kahao){
				that.$message.error('请输入卡号');
				return;
			}
			if(!that.duihuanma){
				that.$message.error('请输入兑换码');
				return;
			}
			bangka({ card_account:that.kahao,card_password:that.duihuanma })
			.then(res => {
				chongqian({ card_account:that.kahao,card_password:that.duihuanma })
				.then(res => {
					that.$message({
						message: '兑换成功',
						type: 'success'
					});
					that.$router.go(0)
				})
				.catch(err => {
					that.$message.error(err.message)
				})
			})
			.catch(err => {
				that.$message.error(err.message)
			})
		}
	}
};
</script>

<style lang="scss" scoped>
	.box {
		position: relative;
		width: 100%;
		background-color: #FFFFFF;
		.header{
			padding: 18px 20px;
			border-bottom: 1px solid #EBEEF5;
			box-sizing: border-box;
		}
		.duihuan{
			width: 450px;
			padding: 18px 20px;
			margin: auto;
			.line{
				margin: 20px 0;
				span{
					color: #fff;
				}
				input{
					width: 300px;
					padding: 10px 10px;
					margin-left: 40px;
					outline: none;
				}
			}
			.button{
				display: block;
				width: 90px;
				padding: 10px 0;
				margin: auto;
				color: #fff;
				background: #FF0036;
				text-align: center;
				border-radius: 10px;
			}
		}
		.table{
			padding: 18px 20px;
		}
	}
</style>
